/* global Component, wrapPost */
class profilePage extends Component {

    static name() {
        return "profilePage";
    }

    static componentName() {
        return "profilePage";
    }

    data() {
        return {
            editAvatar:false,
            activeView:"Orders",
            showReportSubMenu:false,
            response: null,
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            errorAlertDataSession: null,
            errorAlert: null,
            listMode: true,
            loadingImage: false
        };
    }

    getWatch() {
        return {
            "session":function (){
                if(this.session) {
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Province', value: this.session.fields.ProvinceCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Country', value: this.session.fields.Country});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_ZipCode', value: this.session.fields.ZipCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_CenterCost', value: this.session.fields.CenterCost});
                }
            },
            "$route.params.activeView":function (newValue,oldValue){
                if(oldValue != newValue) {
                    if(!newValue)
                        newValue = "Orders";
                    this.changeView(newValue);
                }

            }
        };
    }


    created() {
        return function () {
            this.loadCustomerPersons();
            if(this.$route.params.activeView){
                this.activeView = this.$route.params.activeView;
            }
        };
    }

    updated() {
        return function () {
            if(this.$store.getters.getAppState == "ready" && !this.$store.getters.getIsLogin) {
                this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                this.$router.push("login");
            }

        };
    }

    getComputed() {
        return {
            showInvoiceListInProfile(){
                return this.$store.getters.canShowInvoiceListInProfile;
            },
            enableComplainForm(){
                return this.$store.getters.useComplaintForm;
            },
            orderSerNr:function (){
                return this.$route.params.SerNr;
            },
            viewmode: function (){
                if(this.$store.getters.getUserIsAdmin)
                    return 'alls-persons';
                return 'active-persons';
            },
            session: function () {
                let storeSession = this.$store.getters.getSession;
                if(storeSession)
                    return storeSession;
                return null;
            },
            salesman: function () {
                return this.$store.getters.getSalesMan;
            },
            customer: function () {
                return this.$store.getters.getCustomer;
            },
            isAdmin: function () {
                return this.$store.getters.getUserIsAdmin;
            },
            isB2B: function () {
                return this.$store.getters.getSetting.RetailMode == "B2B";
            },
            avatarUrl: function () {
                if(this.$store.getters.getAvatar)
                    return this.$store.getters.getAvatar;
                else return "../static/ecommerce/img/user.png";
            },
        };
    }

    getMethods() {
        return {
            clearFile: this.clearFile,
            resetDefault: this.resetDefault,
            submitFile: this.submitFile,
            clearAvatar: this.clearAvatar,
            changeView:this.changeView,
            activeReport:this.activeReport,
            logout: this.logout,
            loadCustomerPersons: this.loadCustomerPersons,
            updateSelectValues:this.updateSelectValues,
            loadSelects:this.loadSelects,
            EditProfileSession:this.EditProfileSession,
            changePassword: this.changePassword,
        };
    }

    clearFile(){
        const fileInput = document.querySelector('#avatarFile');
        fileInput.value = '';
        this.editAvatar = false;
    }

    resetDefault(){
        // eslint-disable-next-line no-alert
        if (confirm(this.tr('Do you want to reset your profile image to default?'))){
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/resetAvatar/${this.session.fields.Code}`, {reset:true})
                .then((response)=>{
                    this.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image changed`,type: 'info'});
                    this.clearFile();
                })
                .catch((error)=>{
                    this.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image was't changed`,type: 'error'});
                    this.clearFile();
                    // eslint-disable-next-line no-console
                    console.log('FAILURE!!',error);
                });
        }
    }

    submitFile(){
        let self = this;
        let formData = new FormData();
        let imagefile = document.querySelector('#avatarFile');
        if(imagefile.files.length > 0){
            self.loadingImage = true;
            formData.append("image", imagefile.files[0]);
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/changeAvatar/${this.session.fields.Code}`, formData,{headers: {'Content-Type': 'multipart/form-data'}})
                .then((response)=>{
                    self.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image changed`,type: 'info'});
                    self.clearFile();
                    self.$store.dispatch('updateAvatar',response.data.newURL);
                    self.loadingImage = false;
                })
                .catch((error)=>{
                    self.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image was't changed`,type: 'error'});
                    self.clearFile();
                    self.loadingImage = false;
                });
        }
        else{
            this.$store.dispatch('showNotificacion',{title:"Information", message:`Please select an Image`,type: 'info'});
        }
    }

    clearAvatar(e, key) {
        e.preventDefault();
        e.stopPropagation();
        this.avatarData = null;
    }

    changeView(newactiveView){
        this.activeView = newactiveView;
    }

    activeReport(reportName){
        this.showReportSubMenu = false;
        this.activeView = "Reports";
        this.reportName = reportName;
        setTimeout(()=>this.emitEvent('changeReportView',reportName),500);
    }

    logout() {
        let self = this;
        this.$store.dispatch("logout").then(()=>{
            document.cookie = "connect.ecommerce.sid" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            self.$router.push("/home");
        });
    }

    async loadCustomerPersons() {

    }

    updateSelectValues(){
        this.session.fields.ProvinceCode = this.$store.getters.getSelectLinkToValue('Session_Province');
        this.session.fields.ZipCode = this.$store.getters.getSelectLinkToValue('Session_ZipCode');
        this.session.fields.Country = this.$store.getters.getSelectLinkToValue('Session_Country');
        this.session.fields.DefaultCenterCost = this.$store.getters.getSelectLinkToValue('Session_CenterCost');
    }

    loadSelects() {
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_Province', value: this.session.fields.ProvinceCode});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_Country', value: this.session.fields.Country});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_ZipCode', value: this.session.fields.ZipCode});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_CenterCost', value: this.session.fields.DefaultCenterCost});
    }

    async EditProfileSession() {
        this.errorAlertDataSession = null;
        if (!this.session.fields.Name)
            this.errorAlertDataSession = 'Please fill the field Name';
        else
            if (!this.session.fields.LastName)
                this.errorAlertDataSession = 'Please fill the field Last Name';

        if (!this.errorAlertDataSession) {
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/updatePerson`,this.session)
                .then(response => {
                    this.response = response;
                    if (this.response.data.success) {
                        this.$store.dispatch('showNotificacion',{title: 'Guardar', message: 'Datos Personales guardados',type: 'success'});
                    }
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log('Error response in Register Person', error);
                });
        }
    }

    async changePassword() {
        this.errorAlert = null;
        if (!this.oldPassword)
            this.errorAlert = 'Please type your actual password';
        else if (!this.newPassword)
            this.errorAlert = 'Please type a new password';
        else if (!this.confirmPassword)
            this.errorAlert = 'Please confirm the new password';
        else if (this.newPassword !== this.confirmPassword)
            this.errorAlert = "The Passwords does'nt match";

        if (!this.errorAlert) {
            let data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
            };

            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/changePassword`, data)
                .then(response => {
                    this.response = response;
                    if (!this.response.data.success) {
                        this.errorAlert = this.response.data.errors;
                        this.oldPassword = null;
                        this.newPassword = null;
                        this.confirmPassword = null;
                    } else {
                        this.oldPassword = null;
                        this.newPassword = null;
                        this.confirmPassword = null;
                        this.$store.dispatch('showNotificacion', {
                            title: 'Cambio de Password',
                            message: 'Password cambiado satisfactoriamente',
                            type: 'success'
                        });
                    }
                });

            
        }
    }

    getTemplate() {
        return `<div class="profile-view">
                    <headerSectionComponent headerTitle="Actualización de Datos" :renderMenu="false" :renderSearch="false"></headerSectionComponent>
                    <div class="section white" :class="classContainer">
                        <template v-if="!isLoading">
                            <div style="min-height: 36.5rem;">
                                <section class="padding-small">
                                    <div class="container-fluid p-1 p-sm-2">
                                        <div class="row">
                                            <div class="customer-sidebar col-xl-2 col-lg-3 mb-md-5 text-sm-left">
                                                <div class="col-12">
                                                    <a class="navbar-brand col-12" href="#"><h5 class="title">{{tr('Your profile')}}</h5></a>
                                                    <div class="list-group" id="navbarText">
                                                        <a class="list-group-item list-group-item-action" data-toggle="list" href="#/profile" @click="logout">
                                                            <span><i class="icon fas fa-sign-out-alt"></i> {{tr('Log Out')}}</span>
                                                        </a>
                                                        <template v-if="$store.getters.getIndicators.length>0">
                                                            <router-link class="nav-item nav-link" :class='{active: activeView=="Indicators"}' :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'Indicators'}}" data-toggle="list" >{{tr("Indicators")}}</router-link>
                                                        </template>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Orders"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'Orders'}}" >{{tr("Orders")}}</router-link>
                                                        <router-link v-if="showInvoiceListInProfile" class="list-group-item list-group-item-action" :class='{active: activeView=="Invoices"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'Invoices'}}" >{{tr("Invoices")}}</router-link>
                                                        <template v-if="isAdmin">
                                                            <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Addresses"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile',params:{activeView:'Addresses'}}" >{{tr('Addresses')}}</router-link>
                                                            <span class="list-group-item list-group-item-action dropdown-toggle" data-toggle="list" :class='{active: activeView=="Reports"}' role="button"  aria-haspopup="true" aria-expanded="false" @click.stop="showReportSubMenu=!showReportSubMenu">{{tr("Reports")}}</span>
                                                            <div class="custom-dropdown-menu" v-if="showReportSubMenu">
                                                                <template v-for="(report, index) in $store.getters.getReports">
                                                                    <a href="#/profile" @click=activeReport(report.fields.ReportName) :class='{active: activeView==report.fields.ReportName}' class="list-group-item list-group-item-action dropdown-toggle">
                                                                        {{' >'+tr(report.fields.ReportTitle)}}
                                                                    </a>
                                                                </template>
                                                            </div>
            
                                                            <router-link v-if="isB2B" class="list-group-item list-group-item-action" :class='{active: activeView=="Persons"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'Persons'}}" >{{tr('Persons')}}</router-link>
                                                            <router-link v-if="isB2B" class="list-group-item list-group-item-action" :class='{active: activeView=="CenterCost"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile',params:{activeView:'CenterCost'}}" >{{tr('Cost Center')}}</router-link>
                                                        </template>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Profile"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'Profile'}}"  >{{tr('Profile')}}</router-link>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="ChangePassword"}' data-toggle="list" :to="{name:'ProfileLinkAlias',path:'profile' , params:{activeView:'ChangePassword'}}" >{{tr('Change Password')}}</router-link>
                                                        <router-link to="/complaint" class="list-group-item list-group-item-action" v-if="enableComplainForm">{{tr("Help Desk")}}</router-link>
                                                    </div>
                                                    <div class="col-12 row justify-content-center m-0 p-0 pr-1">
                                                        <template v-if="loadingImage">
                                                            <div class="avatar-container py-5">
                                                                <i class="fas fa-spinner fa-spin fa-2x"></i>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="avatar-container">
                                                                <img :src="avatarUrl" alt="Avatar Profile" class="rounded rounded-circle d-block custom-avatar" />
                                                                <span v-if="!editAvatar" class="edit-button" @click="editAvatar=true" :title="tr('Edit')" ><i class="fa fa-edit"></i></span>
                                                            </div>
                                                        </template>
                                                        <div class="change-avatar col-12 mt-1 p-2" v-if="editAvatar">
                                                            <div class="form-group mb-2">
                                                                <span class="close-button" :title="tr('Cancel')" @click="clearFile"><i class="fas fa-times"></i></span>
                                                                <div class="custom-file">
                                                                    <input type="file" id="avatarFile" class="file-input" hidden @change="submitFile">
                                                                    <label for="avatarFile" class="custom-file-label" :data-browse="tr('Change Avatar')">{{tr("Change Avatar")}}</label>
                                                                </div>
                                                            </div>
                                                            <!--<div class="form-group text-center mb-0">
                                                                <button class="btn btn-secondary" :title="tr('Reset Image')" @click="resetDefault"><i class="icon fa fa-redo"></i></button>
                                                            </div>-->
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0 text-center text-lg-left">
                                                        <p class="name">{{session.fields.Name}} {{session.fields.LastName}}</p>
                                                        <p class="customer">{{tr('Customer')}}: <span v-if="customer">{{customer.Name}}</span></p>
                                                        <p class="text-muted text-small" v-if="salesman">
                                                            {{tr("Your account executive is")}}:<br>
                                                            {{salesman.Name}} {{salesman.LastName}} <br>
                                                            {{salesman.Phone}}<br>
                                                            {{salesman.Email}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-10 col-lg-9 pl-lg-3 mt-3">
                                                <div class="tab-content p-3">
                                                    <div v-if="activeView=='Addresses'" id="AddressesView" >
                                                        <addressesManagerComponent> </addressesManagerComponent>
                                                    </div>
                                                    <div v-if="activeView=='Orders'" id="OrderView" >
                                                        <orderViewComponent orderId="orderSerNr"> </orderViewComponent>
                                                    </div>
                                                    <div v-if="activeView=='Invoices'" id="InvoiceView" >
                                                        <invoice-list />
                                                    </div>
                                                    <div v-if="isAdmin && activeView=='Reports'" id="ReportView" >
                                                        <genericReportPage :key="'reportView'+reportName"></genericReportPage>
                                                    </div>
                                                    <div  v-if="isAdmin && activeView=='Indicators'" id="OrderAuthView" >
                                                        <genericIndicatorPage> </genericIndicatorPage>
                                                    </div>
            
                                                    <div  v-if="isAdmin && isB2B && activeView=='Persons'" id="AdminPerson" >
                                                        <PersonManagerComponent></PersonManagerComponent>
                                                    </div>
                                                    <div v-if="isAdmin && isB2B && activeView=='CenterCost'" id="AdminCenterCosts" >
                                                        <centerCostManagerComponent></centerCostManagerComponent>
                                                    </div>
                                                    <div v-if="activeView=='ChangePassword'" id="ChangePassword" >
                                                        <div class="col-sm-12">
                                                            <div ref='errorChangePassword' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorAlert">
                                                                <strong>{{tr('Error')}}: {{tr(errorAlert)}}!</strong>
                                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorAlert = ''">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="content-block">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_old" class="form-label">{{tr('Old Password')}}</label>
                                                                        <input id="password_old" type="password" class="form-control" v-model="oldPassword">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_1" class="form-label">{{tr('New Password')}}</label>
                                                                        <input id="password_1" type="password" class="form-control" v-model="newPassword">
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_2" class="form-label">{{tr('Retype New Password')}}</label>
                                                                        <input id="password_2" type="password" class="form-control" v-model="confirmPassword">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- /.row-->
                                                            <div class="text-right">
                                                                <button class="btn btn-custom change-password" @click.prevent="changePassword"><span class="icon"><i class="fa-icon fa fa-save"></i></span> {{tr('Change Password')}}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  v-if="activeView=='Profile'" id="Profile" >
                                                        <h5 class="my-4">{{tr('Personal Details')}}</h5>
                                                        <div class="content-block">
                                                            <div ref='errorEditProfile' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorAlertDataSession">
                                                                <strong>{{tr('Error')}}: {{tr(errorAlertDataSession)}}!</strong>
                                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorAlertDataSession = ''">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-6">
            
                                                                    <div class="form-group">
                                                                        <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                                                        <input id="firstname" type="text" class="form-control" v-model="session.fields.Name" >
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="lastname" class="form-label">{{tr('Last Name')}}</label>
                                                                        <input id="lastname" type="text" class="form-control" v-model="session.fields.LastName" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- /.row-->
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="WorkAddress" class="form-label">{{tr('Address')}}</label>
                                                                        <input id="WorkAddress" type="text" class="form-control" v-model="session.fields.WorkAddress">
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="Province" class="form-label">{{tr('Province')}}</label>
                                                                        <selectLinkToComponent  recordKey="Session" table="Province" paste="Code" showField="Name" :selected="session.fields.ProvinceCode" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="zip" class="form-label">{{tr('ZIP')}}</label>
                                                                        <selectLinkToComponent recordKey="Session" table="ZipCode" paste="Code" showField="Code" :selected="session.fields.ZipCode" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="company" class="form-label">{{tr('Country')}}</label>
                                                                        <selectLinkToComponent recordKey="Session" table="Country" paste="Code" showField="Name" :selected="$store.getters.getSelectLinkToValue('Country')" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                                                                    </div>
                                                                </div>
            
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="phone" class="form-label">{{tr('Personal Phone')}}</label>
                                                                        <input id="phone" type="text" class="form-control"  v-model="session.fields.Phone">
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="email" class="form-label">{{tr('Email')}}</label>
                                                                        <input id="email" type="text" class="form-control" v-model="session.fields.Email">
                                                                    </div>
                                                                </div>
            
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="company" class="form-label">{{tr('Cost Center')}}</label>
                                                                        <template v-if="isAdmin">
                                                                            <selectLinkToComponent recordKey="Session" table="CenterCost" paste="Code" showField="Name" :selected="session.fields.DefaultCenterCost" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                                                                        </template>
                                                                        <template v-else>
                                                                            <input type="text" readonly class="form-control-plaintext" id="staticEmail" :placeholder="tr('Cost Center')" :value="session.fields.DefaultCenterCost">
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 text-right">
                                                                    <button class="btn btn-custom save-changes" @click="EditProfileSession"><span class="icon"><i class="fa-icon fa fa-save mr-1"></i></span>  {{tr('Save Changes')}}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row">
                                <div class="loading" style="min-height: 36.5rem;">
                                    <i class="fas fa-spinner fa-spin fa-3x"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

profilePage.registerComponent();
